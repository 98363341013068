export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {

    //Ticket Submission
    const socialBtn = document.querySelector('button.social');
    const environmentalBtn = document.querySelector('button.environmental');
    const caseStudies = document.querySelectorAll('.casestudy-item');
    const environmentalCategories = document.querySelector('.environmental-categories');
    const socialCategories = document.querySelector('.social-categories');
    const allCategories = document.querySelector('.checkmark-filter-container');

    // Case Study Filter
      function filtercasestudies(button) {
        // const casestudies = document.querySelector('.casestufy-item')
        let programType = button.dataset.program

        caseStudies.forEach((caseStudy) => {
          if(programType) {
            let caseStudyProgramType = caseStudy.dataset.program
          caseStudy.classList.add('hide')
          if(caseStudyProgramType === programType) {
            caseStudy.classList.remove('hide')
          }
          }
        })
    }

    socialBtn.addEventListener('click', function(e) {
      e.preventDefault()
      this.classList.remove('inactive')
      this.classList.add('active')
      environmentalBtn.classList.add('inactive')
      environmentalCategories.classList.add('hide')
      socialCategories.classList.remove('hide')
      filtercasestudies(socialBtn)
    })

    environmentalBtn.addEventListener('click', function(e) {
      e.preventDefault()
      this.classList.remove('inactive')
      this.classList.add('active')
      socialBtn.classList.add('inactive')
      environmentalCategories.classList.remove('hide')
      socialCategories.classList.add('hide')
      filtercasestudies(environmentalBtn)
    })

    allCategories.querySelectorAll('input').forEach((checkbox) => {
      checkbox.addEventListener('change', function() {
        let count = 0
        $('.casestudy-item').addClass('hide')

       allCategories.querySelectorAll('input').forEach((item) => {
        if(item.checked) {
          count += 1
          let value = item.value
          if(count > 0) {
            caseStudies.forEach((study) => {
              if(study.dataset.categories.includes(value)) {
               study.classList.remove('hide')
              }
            })
          } 
        }
       })
       if(count === 0) {
        filtercasestudies(document.querySelector('.program-buttons > a:not(.inactive)'))
      }
      })
    })

    // Initial Call
   if(socialBtn) {
    filtercasestudies(socialBtn)
   }

  },
};
