export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {

    // JavaScript to be fired on all pages, after page specific JS is fired
    $('.icon').next().addClass('padding-left');

    $('.sidebar .toggle').on('click', function() {
      $(this).next().slideToggle();
    })

    //Prevent form resubmission on page refresh
    if(window.history.replaceState) {
      window.history.replaceState( null, null, window.location.href );
    } 

    //prevent scroll to top on form submission
    history.scrollRestoration = 'manual';

    //Ticket Submission
    if(document.getElementById('ticket-form')) {
      const ticket_form = document.getElementById('ticket-form');
      const submit_button = document.getElementById('help-modal-submit');
      const modal_content = document.querySelector('.modal-content');
  
      ticket_form.addEventListener('submit', function(e) {
        const form = e.target;
        submit_button.disabled = true;
  
        // Post data using the Fetch API
        fetch(form.action, {
          method: form.method,
          body: new FormData(form),
        })
        .then(response => response.text())
        .then(function(data){
          // console.log(data)
          modal_content.innerHTML = data
        })
        .finally(() =>
          submit_button.disabled = false
        );
    
        e.preventDefault();
      });
  
      $('#help-button').click(function() {
        $('#help-modal').addClass('active');
        modal_content.appendChild(ticket_form);
      });
  
      $('#help-modal-close').click(function(){
        $('#help-modal').removeClass('active');
      });
    }

    //Javascript needed for custom file inputs on uploaded files
    const change_file_inputs = document.querySelectorAll('.file-replace .change-file');
    if(change_file_inputs) {
      change_file_inputs.forEach(function(element) {
        element.addEventListener('click', function() {
          //fire click event on actual file input
          this.nextElementSibling.click();
          //hide uploaded file label
          let file_replace_field = this.parentElement
          let label = file_replace_field.querySelector('.general-label')
          label.style.display = 'none';
          this.style.display = 'none';
          this.nextElementSibling.style.display = 'block';
        })
      });
    }
  },
};
