// import external dependencies
import 'jquery';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import createAccount from './routes/createAccount';
import caseStudies from './routes/caseStudies';


/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  //Create Account page
  createAccount,
  //Case Studies page
  caseStudies,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
